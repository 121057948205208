import '../assets/scss/footer.scss'
import logo_s_select_footer from '../assets/images/s-select-footer.png'

import ic_location from '../assets/icons/Vector.svg'
import ic_facebook from '../assets/icons/ic_facebook.svg'
import { FB_LINK, LanguageList, YOUTUBE_LINK, YOUTUBE_LINK_PAGE, languageJA } from '../constant'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ROUTER } from '../router/config'
import { useGetStoreListQuery } from '../app/features/common'
import { selectLanguage } from '../app/slice'
import { useAppSelector } from '../app/hooks'
import video1 from "../assets/video/video1.mp4"
import video2 from "../assets/video/video2.mp4"

export function Footer() {
  const { t } = useTranslation()
  const { data: listStore } = useGetStoreListQuery({})
  const language = useAppSelector(selectLanguage)
  const languageList = LanguageList
  const langItem = languageList.find((item) => item.id === language) || languageList[0]
  return (
    <div className='footer' id={'footer'}>
      <div className='wrap-container footer-content'>
        <div className='footer-content__info'>
          <div className='row no-margin-hoz content-info'>
            <div className='col-sm-3 logo-wrap'>
              <img src={langItem.id === languageJA ? logo_s_select_footer : logo_s_select_footer} alt={'logo-omi'} />

              <h4>{t('footer.companyName')}</h4>
            </div>
            <div className='col-sm-3'>
              <div className='footer-item-info'>
                <div className='icon-info'>
                  <img src={ic_location} alt={'icon-info'} />
                </div>
                <p>
                  <a target='_blank' rel="noopener noreferrer" href={'https://www.sugi-hd.co.jp'}>SUGI-NET.JP</a>
                </p>
                
              </div>
              <div className='footer-item-info'>
                <div className='icon-info'>
                  <img src={ic_location} alt={'icon-info'} />
                </div>
                <div>
                  <p>
                    <a target='_blank' rel="noopener noreferrer" href={'https://www.strading.co.jp'}>STRADING.CO.JP</a>
                  </p>
                </div>
              </div>
              <div className='footer-item-info'>
                <div className='icon-info'>
                  <img src={ic_facebook} alt={'icon-info'} />
                </div>
                <p>
                  <a target='_blank' rel="noopener noreferrer" href={'https://www.facebook.com/people/S-Select-Global/61564870224636/?mibextid=LQQJ4d'}>S-Select Global</a>
                </p>
              </div>
            </div>
            <div className='col-xl-3 col-lg-5'>
              
            </div>
            <div className='col-sm-3 link-full'>
              <div className=' footer-top__right other'>
                <div className='footer-top__right'>
                  <Link to={ROUTER.ABOUT_WEB}>
                    {t('footer.omipharmaLink')}
                  </Link>
                </div>
                <div className='footer-top__right'>
                  <Link to={ROUTER.SITE_MAP}>
                    {t('footer.sitemap')}
                  </Link>
                </div>
                <div className='footer-top__right'>
                  <Link to={ROUTER.FAQ}>
                    {t('footer.faq')}
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className='col-xl-3 col-lg-5 content-copyright__logo video'>
              <div className='box-video'>
                <video controls>
                  <source src={video1} />
                </video>
              </div>
              <div className='box-video'>
                <video controls>
                  <source src={video2} />
                </video>
              </div>
            </div> */}
          </div>
          {/* <div className='row no-margin-hoz content-copyright mt-3'>
            <div className='col-xl-8 col-lg-7 content-copyright__text'>
              <h4>{t('footer.companyName')}</h4>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
